import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {DateInput, Input, MultiSelect, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange, baseHandleListChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {deleteCourseMaterial, getCourse} from "../../../api/courses";
import {DeleteButton} from "../../elements/DeleteButton";
import {addFileToHomeworkMaterial, deleteHomeworkMaterial, homeworkApi} from "../../../api/class_register";
import {AddButton} from "../../elements/Buttons";
import {useQueryClient} from "@tanstack/react-query";


export default function HomeworkCreateEdit() {

    const {id, courseId} = useParams()
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        id: null,
        title: "",
        type: "Sonderaufgabe",
        task: null,
        course: {id: parseInt(courseId) || null},
        homework_materials: []
    })

    const schema = {
        task: Joi.string().required(),
        due_date: Joi.date().required()
    }

    const [course, setCourse] = useState()
    const [newRowId, setNewRowId] = useState(1)
    const [errors, setErrors] = useState({}) // validation errors
    const [materialErrors, setMaterialErrors] = useState([])

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {

        let newData
        let course
        if (id) {
            newData = await homeworkApi('get', id)
            course = await getCourse(newData.course.id)
        } else if (courseId) {
            course = await getCourse(courseId)
            newData = {...data}
        }

        setData(newData)
        setCourse(course)
    }

    const homeworkType = data.id ? data.type : 'Sonderaufgabe'
    const title = (id || data.id) ? `${homeworkType} bearbeiten` : `${homeworkType} eintragen`
    const successMessage = (id || data.id) ? `${homeworkType} aktualisiert` : `${homeworkType} hochgeladen`

    async function onSave() {

        // saving the instance first
        let newData

        let tmpData = {...data, homework_materials: data.homework_materials.map((t, index) => {
            if (t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })}

        if (!data.id) newData = await homeworkApi('post', null, tmpData)
        else newData = await homeworkApi('patch', null, tmpData)

        // add the files
        for (let i = 0; i < data.homework_materials.length; i++) {
            const m = data.homework_materials[i]
            if (m.isFilePicked) {
                const formData = new FormData();
                formData.append('file', m.selectedFile);
                await addFileToHomeworkMaterial(formData, newData.homework_materials[i].id)
            }
        }

        await loadData()
        queryClient.invalidateQueries({ queryKey: ['homework_detail', id] })
        toast.success(successMessage)
        navigate(`/class-register/homework/${newData.id}`)
    }

    async function onDelete() {
        await homeworkApi('delete', data.id)
        toast.success(`${homeworkType} gelöscht.`)
        navigate(`/class-register/courses/${data.course.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleChangeFile (event, rowId) {
        setMaterials(data.homework_materials.map(row => {
                if (row.id === rowId) {
                    return {...row, selectedFile: event.target.files[0], isFilePicked: true}
                }
                return row
            }
        ))
    }

    function addRow() {
        const newRow = {
            id: `N${newRowId}`,
            file: null,
            title: "",
            description: null,
            homework: data.id,
            selectedFile: null,
            isFilePicked: false
        }
        setNewRowId(newRowId + 1)
        setMaterials([...data.homework_materials, newRow])
    }

    function setMaterials(newMaterials) {
        setData({...data, homework_materials: newMaterials})
    }

    function handleMaterialChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.homework_materials, setMaterials, materialErrors, setMaterialErrors, {})
    }

    async function handleDeleteMaterial(hwMaterialId) {
        if (hwMaterialId.toString().startsWith("N")) {
            const newMaterials = data.homework_materials.filter(row => row.id !== hwMaterialId)
            setMaterials(newMaterials)
        } else {
            try {
                await deleteHomeworkMaterial(hwMaterialId)
                const newMaterials = data.homework_materials.filter(row => row.id !== hwMaterialId)
                setMaterials(newMaterials)
                loadData()
                toast.success("Material gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    if (!course) return <></>
    return (

        <NarrowPage title={title}>

            {course &&
                <h2 className="text-md font-bold text-gray-900">{course.course_number} {course.school_subject.name}</h2>}

            <Form id="createCourseInstruction" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    {data.type === 'Sonderaufgabe' && <MultiSelect
                        path="students"
                        label="Schüler"
                        className="sm:col-span-6"
                        options={course.students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'students')}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />}

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="task"
                        type="text"
                        label="Beschreibung"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={3}
                    />

                    <DateInput
                        path="due_date"
                        label="Zu erledigen bis"
                        className="sm:col-span-3"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />


                    <div className="sm:col-span-6 text-sm">
                        <label className="mb-2 block text-lg font-medium text-gray-900">
                            Materialien
                        </label>

                        {data.homework_materials.map((m, index) => (
                            <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                <div className="border-t border-gray-200 px-4 py-5">

                                        <FormSectionContent>

                                            <div className="sm:col-span-6 mb-0">
                                                {m.file && <p className="py-1">Bisherige Datei: {m.file}</p>}

                                                <input multiple={false} type="file" name="file"
                                                       onChange={(event) => handleChangeFile(event, m.id)}/> <br/>
                                            </div>

                                            <Input
                                                path="title"
                                                type="text"
                                                label="Titel"
                                                className="sm:col-span-6"
                                                onChange={handleMaterialChange}
                                                errors={materialErrors}
                                                data={data.homework_materials}
                                                rowId={m.id}
                                            />

                                            <TextArea
                                                path="description"
                                                label="Bescheibung"
                                                className="sm:col-span-6"
                                                onChange={handleMaterialChange}
                                                errors={materialErrors}
                                                data={data.homework_materials}
                                                rowId={m.id}
                                                rows={3}
                                            />

                                            <DeleteButton
                                                classNameIn="sm:col-span-6 flex justify-end"
                                                onDelete={() => handleDeleteMaterial(m.id)}/>

                                        </FormSectionContent>

                                </div>
                            </div>))}


                        <div className="my-4 ml-4">
                            <AddButton onClick={addRow} label="Material hinzufügen"/>
                        </div>

                    </div>

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(`/class-register/courses/${data.course.id}`)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zum Kursbuch</span>

        </NarrowPage>
    )
}
