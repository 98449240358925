import {useAuth} from "./../../contexts/AuthContext"
import {useQuery} from "@tanstack/react-query";
import {getAnnouncementFeed, getNotificationFeed} from "../../api/organizational";
import {formatDate, formatDateTime} from "../../utils/formatters";
import {PaperClipIcon} from "@heroicons/react/20/solid";
import React from "react";
import {EditButton, PrimaryButton, SecondaryButton} from "../elements/Buttons";
import checkPermission from "../../utils/permissions";
import {Link} from "react-router-dom";
import {getAbsenceNoticeFeed, getPenaltyFeed} from "../../api/logbook";
import ListViewTable, {ColumnWithImage, TableLink} from "../elements/tables/ListViewTable";
import MyTimetableChanges from "../modules/timetables/MyTimetableChanges";
import {getProjectFeed} from "../../api/projects";
import {getAssessmentWidgetData} from "../../api/assessments";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useUserInfo} from "../../contexts/UserInfoContext";
import {getStatusSummaryData} from "../../api/plupus";
import {useAdjustedDate} from "../../contexts/AdjustedDateContext";
import {getRatingIcon} from "../modules/plupus/StudentDetailV2";
import env from "react-dotenv";
import Alert from "../elements/Alert";
import {getUserInfo, getUsers} from "../../api/accounts";

export default function Dashboard() {

    const {currentUser, currentUserId, permissions} = useAuth()
    const {data: user} = useQuery(['self_info', currentUserId], () => getUsers(),
        {enabled: !!currentUserId}
        )

    const canViewAbsenceNotices = checkPermission(permissions, "student_logbook.view_absencenotice")
    const isStudentOrParent = currentUser.groups.findIndex(g => g.name === "Schüler" || g.name === "Eltern") >= 0

    return (
        <div className="py-6 bg-gray-50">

            {currentUserId && user && user[0]?.is_password_expired && <div className="sm:px-6 lg:max-w-7xl">
                <Alert variant="warning">
                    Dein Passwort ist älter als 3 Monate.
                    Bitte <Link className="font-medium underline" to={`/settings/change-password`}>aktualisiere dein Passwort jetzt</Link>.
                   </Alert>
            </div>}

            {(currentUser.staff_id || currentUser.student_id || isStudentOrParent || canViewAbsenceNotices) &&
                <>
                    <div className="grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                        {(isStudentOrParent || canViewAbsenceNotices) &&
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <AbsenceNoticeFeed/>
                            </div>}



                    </div>

                    <div className="grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        {currentUser.student_id && <div className="space-y-6 lg:col-span-1">
                            <Projects/>
                        </div>}

                        {currentUser.student_id && <div className="space-y-6 lg:col-span-2">
                            <PenaltyFeed/>
                        </div>}
                    </div>
                </>
            }

            {currentUser.staff_id &&
                <div className="grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4 mb-8">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        <Assessments/>
                    </div>
                </div>}

            <div className="grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 mb-8">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                    <MyTimetableChanges dashboard={true}/>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    <AnnouncementFeed/>
                </div>


                <div className="space-y-6 lg:col-span-1">
                    {isStudentOrParent && env.SCHOOL === 'KPI' && <PluPuStatusWidget/>}
                    <ActivityFeed/>
                </div>
            </div>
        </div>
    )
}


function AnnouncementFeed() {

    const {renderUserImage} = useUserInfo();
    const {currentUser, permissions} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["announcements", currentUser.user_id],
        () => getAnnouncementFeed(),
        {}
    )

    const canEdit = checkPermission(permissions, "organizational.change_announcement")
    const canAdd = checkPermission(permissions, "organizational.add_announcement")

    if (!data) return <></>
    return (
        <div>
            <div className="mt-4 px-4">

                <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Tweets
                        </h2>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <SecondaryButton label="Alle ansehen" link={`service/announcements`}/>
                        {canAdd && <PrimaryButton
                            label="Tweet erstellen"
                            link={`/service/announcements/new`}
                        />}
                    </div>
                </div>

                <ul role="list" className="space-y-4">
                    {data.map((announcement) => (
                        <li key={announcement.id} className="bg-white px-4 py-6 shadow sm:rounded-lg sm:p-6">
                            <article aria-labelledby={'question-title-' + announcement.id}>
                                <div>
                                    <div className="flex space-x-3">
                                        <div className="flex-shrink-0">
                                            {renderUserImage(announcement.created_by.id)}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="text-sm font-medium text-gray-900">
                                                {announcement.created_by.full_name}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                <a href={announcement.href} className="hover:underline">
                                                    <time
                                                        dateTime={announcement.date_published}>{formatDateTime(announcement.date_published)}</time>
                                                </a>
                                            </p>
                                        </div>
                                        <div className="flex flex-shrink-0 self-center">
                                            {canEdit && <EditButton iconOnly={true}
                                                                    link={`/service/announcements/${announcement.id}/edit`}/>}
                                        </div>
                                    </div>
                                    <h2 id={'question-title-' + announcement.id}
                                        className="mt-4 text-base font-medium text-gray-900">
                                        {announcement.title}
                                    </h2>
                                </div>
                                <div
                                    className="mt-2 space-y-4 text-sm text-gray-700 overflow-hidden"
                                    dangerouslySetInnerHTML={{__html: announcement.content}}
                                />


                                {announcement.image && <div
                                    className="mt-2 space-y-4 text-sm text-gray-700"
                                ><LazyLoadImage src={announcement.image} alt={announcement.title}/>

                                </div>}


                                <div className="mt-6 flex justify-between space-x-8">
                                    <div className="flex space-x-6">

                                        <div>
                                            {announcement.downloads.map((download, index) => {
                                                if (download.file) {
                                                    return <a key={index} href={download.file} className="flex text-sm"
                                                              target="_blank">
                                                        <PaperClipIcon
                                                            className="cursor-pointer mr-3 h-5 w-5"
                                                        />
                                                        {download.get_file_name}
                                                    </a>
                                                }
                                            })}
                                        </div>
                                        {/*<span className="inline-flex items-center text-sm">*/}
                                        {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
                                        {/*    <HandThumbUpIcon className="h-5 w-5" aria-hidden="true"/>*/}
                                        {/*    <span className="font-medium text-gray-900">{announcement.likes}</span>*/}
                                        {/*    <span className="sr-only">likes</span>*/}
                                        {/*  </button>*/}
                                        {/*</span>*/}
                                        {/*            <span className="inline-flex items-center text-sm">*/}
                                        {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
                                        {/*    <ChatBubbleLeftEllipsisIcon className="h-5 w-5" aria-hidden="true"/>*/}
                                        {/*    <span className="font-medium text-gray-900">{announcement.replies}</span>*/}
                                        {/*    <span className="sr-only">replies</span>*/}
                                        {/*  </button>*/}
                                        {/*</span>*/}
                                        {/*            <span className="inline-flex items-center text-sm">*/}
                                        {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
                                        {/*    <EyeIcon className="h-5 w-5" aria-hidden="true"/>*/}
                                        {/*    <span className="font-medium text-gray-900">{announcement.views}</span>*/}
                                        {/*    <span className="sr-only">views</span>*/}
                                        {/*  </button>*/}
                                        {/*</span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="flex text-sm">*/}
                                        {/*<span className="inline-flex items-center text-sm">*/}
                                        {/*  <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">*/}
                                        {/*    <ShareIcon className="h-5 w-5" aria-hidden="true"/>*/}
                                        {/*    <span className="font-medium text-gray-900">Share</span>*/}
                                        {/*  </button>*/}
                                        {/*</span>*/}
                                    </div>
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    )
}


function ActivityFeed() {

    const {currentUser} = useAuth()
    const {renderUserImage} = useUserInfo();
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["notifications", currentUser.user_id],
        () => getNotificationFeed(),
        {}
    )

    if (!data) return <></>
    return (
        <div>
            <div className="mt-4">

                <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                            Aktivitäten
                        </h2>
                    </div>
                </div>

                <ul role="list" className="divide-y divide-gray-200 bg-white ">
                    {data.notifications?.map((activity) => (
                        <li key={activity.id}
                            className={"py-4 px-4" + (data.last_login > activity.creation_date ? " bg-gray-100" : " bg-white")}>
                            <Link to={activity.summary_link ? new URL(activity.summary_link).pathname : ""}>
                                <div className="flex space-x-3">

                                    <div className="flex flex-col gap-2">
                                        {activity.triggered_by && renderUserImage(activity.triggered_by.id)}
                                        {activity.summary_student && renderUserImage(activity.summary_student.user_id)}
                                    </div>
                                    <div className="flex-1 space-y-1">
                                        <div className="flex items-center justify-between">
                                            <h3 className="text-sm font-medium">{activity.triggered_by?.full_name}</h3>
                                            <p className="text-sm text-gray-500">{formatDateTime(activity.creation_date)}</p>
                                        </div>
                                        <p className="text-sm text-gray-500"
                                           dangerouslySetInnerHTML={{__html: activity.summary}}>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    )
}


function PluPuStatusWidget() {
    const {currentUser} = useAuth()
    const {currentDate} = useAdjustedDate()
    const pluPuStatusStart = new Date(2024, 10, 18);
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_v2_status_summary", currentUser.user_id],
        () => getStatusSummaryData()
    );

    if (!data) return <></>
    return (

        <div>
            <div className="mt-4 px-4">

                <div className="sm:flex sm:items-center mb-2">
                    <div className="flex w-full justify-between justify-items-start">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Dein PluPu-Status
                        </h2>
                        <SecondaryButton label="Details" link={`plupus/v2/my`}/>
                    </div>
                </div>

                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">

                    {pluPuStatusStart <= currentDate && <div
                        className="max-w-3xl mx-auto grid grid-cols-1 sm:px-2 lg:max-w-7xl lg:grid-flow-col-dense">
                        <div className="pt-5 pb-3 sm:px-2">
                        <p className="text-sm text-gray-900">
                                Dein aktueller Status bis {formatDate(data.current_period_end_date)}:
                                {data.current_rating &&
                                    <img src={getRatingIcon(data.current_rating)} alt={data.current_rating}
                                         width={30}
                                         height={30}
                                         className="ml-2 inline-block"/>}
                            </p>
                        </div>

                    </div>}

                    {pluPuStatusStart > currentDate && <div
                        className="max-w-3xl mx-auto grid grid-cols-1 sm:px-2 lg:max-w-7xl lg:grid-flow-col-dense">
                        <div className="pt-5 pb-3 sm:px-2">
                            <p className="text-sm text-gray-900">
                                Dein aktueller Status bis {formatDate(data.current_period_end_date)}:
                                <img src={getRatingIcon("Blau")}
                                     width={30}
                                     height={30}
                                     className="ml-2 inline-block"/>
                            </p>
                        </div>
                    </div>}

                    <div className="max-w-3xl mx-auto grid grid-cols-1 sm:px-2 lg:max-w-7xl lg:grid-flow-col-dense">
                        <div className="pb-5 sm:px-2">
                            <p className="text-sm text-gray-900">
                                Dein prognostizierter Status: {data.forecast_rating}
                                {data.forecast_rating && <img src={getRatingIcon(data.forecast_rating)}
                                                              alt={data.forecast_rating}
                                                              width={30}
                                                              height={30}
                                                              className="ml-2 inline-block"/>}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function AbsenceNoticeFeed() {

    const {currentUser, permissions} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["absence_notice_feed", currentUser.user_id],
        () => getAbsenceNoticeFeed(),
        {}
    )

    const columns = [
        {
            // accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            // columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
            cell: ({row, value}) => (
                <>
                    {row.original.count > 1 && <>{row.original.count} SchülerInnen: {row.original.student_values.join(", ")} </>}
                    {row.original.count <= 1 && <ColumnWithImage
                        userId={row.original.student.user_id}
                        line1={`${row.original.student.last_name}, ${
                            row.original.student.first_name
                        }`}
                        line2={row.original.student.student_number}
                    />}
                </>
            ),
        },
        {
            accessorKey: "student.house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                <>
                    {row.original.count > 1 && <>{row.original.house_values.join(", ")}</>}
                    {row.original.count <= 1 && <>{row.original.student.house}</>}
                </>
            ),
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                <>
                    {row.original.count > 1 && <>{row.original.school_class_values.join(", ")}</>}
                    {row.original.count <= 1 && <>{row.original.student.school_class}</>}
                </>
            ),
        },
        {
            accessorKey: "start",
            id: "start",
            header: "Von",
            columnType: "date_time",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "end",
            id: "end",
            header: "Bis",
            columnType: "date_time",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "reason.name",
            id: "reason",
            header: "Grund",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "notes",
            id: "notes",
            header: "Anmerkungen",
            enableColumnFilter: false,
            classNames: "max-w-md text-gray-500",
            headerClassNames: "max-w-md",
        }
    ]

    function getRowLink(row) {
        return `/logbook/absence-notices/students/${row.original.student.id}`;
    }

    if (!data) return <></>
    return (
        <div>
            <div className="mt-4 px-4">

                <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Heute abwesend
                        </h2>
                    </div>
                </div>

                <div className="">

                    {data.length <= 0 && <div>Keine Abwesenheiten.</div>}

                    {isSuccess && data.length > 0 && (
                        <ListViewTable
                            data={data}
                            columns={columns}
                            isLoading={isLoading}
                            disableGlobalSearch={true}
                            getRowLink={getRowLink}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}


function PenaltyFeed() {

    const {currentUser} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["penalty_feed", currentUser.user_id],
        () => getPenaltyFeed(),
        {}
    )

    const columns = [
        {
            accessorKey: "penalty.name",
            id: "penalty",
            header: "Strafe",
            headerClassNames: "medium-filter",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row.date_from && row.date_to ? `${formatDate(row.date_from)} - ${formatDate(row.date_to)}` : `${formatDate(row.date_from)}`,
            // accessorKey: "date_from",
            id: "date",
            header: "Abzuleisten am",
            // columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        // {
        //     accessorKey: "date_from",
        //     id: "date_from",
        //     header: "Von",
        //     columnType: "date",
        //     enableColumnFilter: false,
        //     classNames: "text-gray-500",
        // },
        // {
        //     accessorKey: "date_to",
        //     id: "date_to",
        //     header: "Bis",
        //     columnType: "date",
        //     enableColumnFilter: false,
        //     classNames: "text-gray-500",
        // },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Anmerkungen",
            enableColumnFilter: false,
            classNames: "max-w-md text-gray-500",
            headerClassNames: "max-w-md",
        }
    ]


    if (!data || data.length === 0) return <></>
    return (
        <div>
            <div className="mt-4 px-4 ">

                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Ausstehende Strafen
                        </h2>
                    </div>
                </div>

                <div className="bg-white mt-3">
                    {isSuccess && data && (
                        <ListViewTable
                            noTopSpace={true}
                            data={data}
                            columns={columns}
                            isLoading={isLoading}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}


function Projects() {

    const {currentUser} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["project_feed", currentUser.user_id],
        () => getProjectFeed(),
        {}
    )

    const columns = [
        {
            accessorKey: "project",
            id: "project",
            header: "Projekt",
            headerClassNames: "medium-filter",
            enableColumnFilter: false,
        },
        {
            accessorKey: "time",
            id: "time",
            header: "Zeit",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        }
    ]


    if (!data || data.length === 0) return <></>
    return (
        <div>
            <div className="my-4 px-4">

                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Projekte heute
                        </h2>
                    </div>
                </div>

                <div className="bg-white mt-3">
                    {isSuccess && data && (
                        <ListViewTable
                            noTopSpace={true}
                            data={data}
                            columns={columns}
                            isLoading={isLoading}
                            disableGlobalSearch={true}
                            getRowLink={null}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}


function Assessments() {

    const {currentUser} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["assessment_widget", currentUser.user_id],
        () => getAssessmentWidgetData(),
        {}
    )
    if (!data || data.assessment_periods.length === 0) return <></>
    const {is_teacher: isTeacher, is_class_teacher: isClassTeacher, is_mentor: isMentor} = data.assessment_permissions

    if (!isTeacher && !isClassTeacher && !isMentor) return <></>

    const columns = [
        {
            accessorKey: "name",
            id: "ap",
            header: "Zeitraum",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <div className="flex gap-4 justify-start">

                    {isTeacher && <TableLink
                        label="Meine Kurse"
                        to={`/assessments/assessment-periods/${row.original.id}/courses/my`}
                    />}

                    {isClassTeacher && row.original.final_conduct_grades_and_remarks && <TableLink
                        label="Meine Klasse"
                        to={`/certificates/assessment-periods/${row.original.id}/class-teachers/${currentUser.staff_id}`}
                    />}

                    {isMentor &&
                        <>{data.houses.map((house, index) => (
                            <TableLink
                                label={`Mein Mentorat (${house.name})`}
                                to={`/assessments/boarding-school/${row.original.id}/houses/${house.id}`}
                            />
                        ))}</>
                    }

                </div>
            ),
            header: "Eingabe starten",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ]


    return (
        <div>
            <div className="mt-4 px-4 ">

                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen eingeben
                        </h2>
                    </div>
                </div>

                <div className="bg-white mt-3">

                    {isSuccess && data && (
                        <ListViewTable
                            noTopSpace={true}
                            data={data.assessment_periods}
                            columns={columns}
                            isLoading={isLoading}
                            disableGlobalSearch={true}
                            getRowLink={null}
                            hideHeader={true}
                        />
                    )}
                </div>
            </div>

        </div>
    )
}
