import {DetailPage} from "../../elements/Pages";
import React, {Fragment, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import {getStudentDetailV2} from "../../../api/plupus";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import {formatDate} from "../../../utils/formatters";
import {useAdjustedDate} from "../../../contexts/AdjustedDateContext";

export function getRatingIcon(rating) {
    return rating === 'Grün' ? '/img/plupu_gruen.png' : rating === 'Orange' ? '/img/plupu_gelb.png' : rating === 'Blau' ? '/img/plupu_blau.png' : null;
}

export default function StudentDetailV2() {

    const {studentId} = useParams()
    const {renderUserImage} = useUserInfo()
    const {currentDate} = useAdjustedDate()
    const pluPuStatusStart = new Date(2024, 10, 28);

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_v2", studentId],
        () => getStudentDetailV2(studentId)
    );

    if (!data) return <></>

    return (
        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            {renderUserImage(data.student.user_id, 80)}
                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"/>
                        </div>
                    </div>
                    <Link to={`/school/students/${data.student.id}`}>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Plupus für {data.student.full_name}</h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.student.student_number}
                            </p>
                        </div>
                    </Link>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                </div>
            </div>

            {!data.is_new_student && pluPuStatusStart <= currentDate &&
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="pluplus-current">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Dein aktueller Status bis {formatDate(data.current_period_end_date)}:
                                        {data.current_rating &&
                                            <img src={getRatingIcon(data.current_rating)} alt={data.current_rating}
                                                 width={40}
                                                 height={40}
                                                 className="ml-2 inline-block"/>}
                                    </h2>
                                </div>
                                <div className="">
                                    <PluPusSummaryTable rows={data.current_lines}/>
                                </div>
                                {data.is_terminated && <div className="px-4 py-5 sm:px-6">
                                    <p className="text-red-600">Dein Vertrag ist gekündigt, daher werden Punktabzüge und -gutschriften in der aktuellen Epoche wirksam.
                                    </p></div>}
                                {data.rankings && <div className="px-4 py-5 sm:px-6">
                                    Damit bist du auf Platz <strong>{data.rankings.rank_all}</strong> von <strong>{data.rankings.total}</strong> SchülerInnen
                                    am KPI und auf Platz <strong>{data.rankings.rank_grade}</strong> von <strong>{data.rankings.total_in_grade}</strong> SchülerInnen
                                    in der Klasse {data.rankings.name}.
                                </div>}
                            </div>
                        </div>
                    </section>
                </div>}

            {pluPuStatusStart > currentDate &&
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="pluplus-current">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Dein aktueller Status bis {formatDate(data.current_period_end_date)}:
                                        <img src={getRatingIcon("Blau")}
                                             width={40}
                                             height={40}
                                             className="ml-2 inline-block"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}

            {!data.is_new_student && !data.is_terminated &&
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="pluplus-forecast">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Dein prognostizierter Status: {data.forecast_rating}
                                        {data.forecast_rating &&
                                            <img src={getRatingIcon(data.forecast_rating)} alt={data.forecast_rating}
                                                 width={40}
                                                 height={40}
                                                 className="ml-2 inline-block"/>}
                                    </h2>
                                </div>
                                <div className="">
                                    <PluPusSummaryTable rows={data.forecast_lines} forecast={true}/>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    {data.forecast_details.next_status_up_rating &&
                                        <span>+{data.forecast_details.points_to_next_status_up} zum Status {data.forecast_details.next_status_up_rating}<br/></span>}
                                    {data.forecast_details.next_status_down_rating &&
                                        <span>{data.forecast_details.points_to_next_status_down} zum Status {data.forecast_details.next_status_down_rating}</span>}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}

            {data.is_new_student &&
                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="pluplus-forecast-new-students">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <p>Du bist neu am Kurpfalz Internat und hast noch keine Beurteilung in der Schule,
                                        im Internat und in der Studierzeit bekommen. Aus diesem Grund siehst du hier
                                        noch keine Prognose für deinen Status im nächsten Beurteilungszeitraum.
                                        Informationen zum Farbensystem findest du im aktuellen KUBU auf deinem iPad
                                        Dock.
                                        Du siehst hier deine aktuellen Punktegut- und -lastschriften:</p>
                                </div>
                                <div className="">
                                    <PluPusSummaryTable rows={data.forecast_lines} forecast={true}/>
                                </div>
                                <div className="px-4 py-5 sm:px-6">
                                    <p>Du musst in Schule, Internat und Studierzeit im Beurteilungszeitraum folgende
                                        Punkte erreichen: </p>
                                    <ul className="px-4 list-disc">
                                        {data.new_student_status_details?.map((d, index) => (
                                            <li key={index}>{d.less_than ? "weniger als" : ""} {d.points_to_goal} für {d.rating}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}


            <div className="mt-2 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                <div className="divide-y divide-gray-200">
                    <div className="px-2 py-5 flex justify-between italic text-sm">
                        <p>Gutschriften, Abzüge und Punktsummen werden täglich gegen Mitternacht aktualisiert.</p>
                    </div>
                </div>
            </div>

        </DetailPage>
    );
}


const standardRows = ['SCHOOL_ASSESSMENT', 'STUDIERZEIT_ASSESSMENT', 'BOARDING_SCHOOL_ASSESSMENT', 'STUDIERZEIT_ROOM_DEFAULT']

function PluPusSummaryTable({
                                rows,
                                forecast
                            }) {
    const [expandedRows, setExpandedRows] = useState({});

    const toggleExpand = (type) => {
        setExpandedRows({
            ...expandedRows,
            [type]: !expandedRows[type],
        });
    };

    const groupRows = (rows) => {
        const grouped = rows.reduce((acc, row) => {
            const type = standardRows.includes(row.type) ? row.type : (row.points >= 0 ? 'POSITIVE_POINTS' : 'NEGATIVE_POINTS');
            acc[type] = acc[type] || [];
            acc[type].push(row);
            return acc;
        }, {});
        return grouped;
    };

    const groupedRows = groupRows(rows);

    const calculatePoints = (typeRows) => {
        return typeRows.reduce((sum, row) => sum + row.points, 0);
    };

    const totalPoints = rows.reduce((sum, row) => sum + row.points, 0);

    const renderTypeRow = (type, displayName) => {
        const typeRows = groupedRows[type] || [];
        const points = calculatePoints(typeRows);
        const hasDetails = typeRows.some(row => row.details);

        if (!hasDetails && points === 0) return <></>
        return (
            <>
                <tr className={"bg-gray-100 leading-6 font-medium text-gray-900"}>
                    <td className="break-word py-4 pl-4 pr-3 flex items-center ">
                        {!expandedRows[type] && <PlusIcon className="mr-2 h-4 w-4 text-imsblue cursor-pointer"
                                                          onClick={() => toggleExpand(type)} aria-hidden="true"/>}
                        {expandedRows[type] && <MinusIcon className="mr-2 h-4 w-4 text-imsblue cursor-pointer"
                                                          onClick={() => toggleExpand(type)} aria-hidden="true"/>}
                        <span className=" cursor-pointer" onClick={() => toggleExpand(type)}>{displayName}</span>
                    </td>
                    <td className="break-word py-4 pl-4 pr-3 text-sm">{points}</td>
                </tr>
                {expandedRows[type] && typeRows.map((row, index) => (
                    <>
                        {row.type == 'SCHOOL_ASSESSMENT' &&
                            <tr key={row.id} className={(index % 2 === 0 ? undefined : "bg-gray-50")}>
                                <td className="break-word py-4 px-4 text-sm whitespace-pre-wrap">

                                    <table className="">
                                        {row.details?.split('\n')
                                            .filter(subject => subject.trim() !== '')
                                            .map((subject, index) => {
                                                const [left, right] = subject.split(' - ');
                                                return (
                                                    <tr key={index}>
                                                        <td className="break-word pr-4 text-sm">{left}</td>
                                                        <td>{right}</td>
                                                    </tr>
                                                );
                                            })}
                                        <tr key="sum">
                                            <td className="font-semibold">Gesamt:</td>
                                            <td className="font-semibold">{row.details?.split('\n')
                                                .filter(subject => subject.trim() !== '').reduce((sum, subject) => {
                                                    const [left, right] = subject.split(' - ');
                                                    const points = parseInt(right);
                                                    return isNaN(points) ? sum : sum + points;
                                                }, 0)}
                                            </td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <i>Formel: (Gesamtzahl Punkte / Anzahl Fächer) * 10. 30 Punkte je Note 1, 20 Punkte
                                        je Note 2, 10 Punkte je Note 3.</i>
                                </td>
                                <td className="break-word py-4 pl-4 pr-3 text-sm">{row.points}</td>
                            </tr>}
                        {row.type != 'SCHOOL_ASSESSMENT' &&
                            <tr key={row.id} className={(index % 2 === 0 ? undefined : "bg-gray-50")}>
                                <td className="break-word py-4 pl-4 pr-3 text-sm whitespace-pre-wrap">
                                    {row.type == 'LOGBOOK_ENTRY' ?
                                        <Link className="text-imsblue-600" to={`/logbook/entries/${row.source_object_id}`}>{row.details}</Link>
                                        : <>{row.details}</>}
                                </td>
                                <td className="break-word py-4 pl-4 pr-3 text-sm">{row.points}</td>
                            </tr>}
                    </>
                ))}
            </>
        );
    };

    return (
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <tbody>
                {renderTypeRow('SCHOOL_ASSESSMENT', 'Schule' + (forecast ? ' (Vorzeitraum)' : ''))}
                {renderTypeRow('BOARDING_SCHOOL_ASSESSMENT', 'Internat' + (forecast ? ' (Vorzeitraum)' : ''))}
                {renderTypeRow('STUDIERZEIT_ASSESSMENT', 'Studierzeit' + (forecast ? ' (Vorzeitraum)' : ''))}
                {renderTypeRow('STUDIERZEIT_ROOM_DEFAULT', 'Studierzeit' + (forecast ? ' (Vorzeitraum)' : ''))}
                {renderTypeRow('POSITIVE_POINTS', 'Gutschriften')}
                {renderTypeRow('NEGATIVE_POINTS', 'Abzüge')}
                <tr>
                    <td className="break-word py-4 pl-4 pr-3 leading-6 font-medium text-gray-900">Summe</td>
                    <td className="break-word py-4 pl-4 pr-3 leading-6 font-medium text-gray-900">{totalPoints}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}