import {Link, useParams} from "react-router-dom";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {extraExamApi, getExtraExamPDF} from "../../../api/school";
import {DownloadButton, EditButton} from "../../elements/Buttons";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {DetailPage} from "../../elements/Pages";
import {formatDate} from "../../../utils/formatters";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";
import {useExams} from "../../../contexts/ExamsContext";


let columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row.student,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
    },
    {
        accessorKey: "work_time",
        id: "work_time",
        header: "Arbeitszeit",
        enableColumnFilter: false,
    },
    {
        accessorKey: "teacher",
        id: "teacher",
        header: "FachlehrerIn",
        cell: (cell) => (
            <>{cell.getValue() &&
                <TableLink
                    label={cell.getValue().full_name}
                    to={`/staff/${cell.getValue().id}`}
                />}
            </>
        ),
        enableColumnFilter: false,
        disableRowLink: true
    }
];

export default function ExtraExamDetailPage() {

    const {id} = useParams()

    const {data: extraExam} = useQuery(
        ["extra_exams", id],
        () => extraExamApi('get', id)
    )

    if (!extraExam) return <></>;
    return (
        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Nachholklausur {formatDate(extraExam.date)}</h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    <DownloadButton
                        apiFunction={() => getExtraExamPDF(id)}
                        fileName={`Nachholklausur.pdf`}
                        label="PDF herunterladen"/>

                    <EditButton
                        link={`/class-register/extra-exams/${id}/edit`}
                        label="Bearbeiten"
                    />

                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white overflow-hidden">
                            {/* Schüler */}
                            {extraExam?.extra_exam_lines &&
                                <ListViewTable
                                    data={extraExam.extra_exam_lines}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                />}
                        </div>
                    </section>

                </div>

            </div>

        </DetailPage>
    )
}


function ExtraExamDetailContent({extraExam, page = false}) {

    const {currentUser, permissions} = useAuth()
    const canEdit = checkPermission(permissions, "school.change_extra_exam") || extraExam.created_by === currentUser.id

    return (
        <>{!page &&
            <div className="px-4 py-5 sm:px-6 flex justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Nachholklausur {formatDate(extraExam.date)}
                </h3>
                {canEdit && <EditButton iconOnly={true} link={`/class-register/extra-exams/${extraExam.id}/edit`}/>}
            </div>}
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Datum</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formatDate(extraExam.date)}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">SchülerInnen</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {extraExam.extra_exam_lines.map((line, index) => (
                                <span key={index}>{index > 0 ? <br/> : ""}
                                    {line.student.last_name}, {line.student.first_name}
                                </span>
                            ) )}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Detailansicht</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ul
                                role="list"
                                className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                    <Link className="ml-4 flex w-0 flex-1 items-center cursor-pointer" to={`/class-register/extra-exams/${extraExam.id}/`}>
                                        <ArrowTopRightOnSquareIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 mr-2"
                                            aria-hidden="true"
                                        />
                                        <p className="font-medium text-imsblue-600 hover:text-imsblue-500">
                                            Öffnen
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </>
    );
}

const ExtraExamDetail = () => {
    const {selectedEvent} = useExams();
    return <ExtraExamDetailContent extraExam={selectedEvent}/>
};

export {
    ExtraExamDetail
};
