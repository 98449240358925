import React from 'react';
import {Route, Routes} from "react-router-dom";

import Students from "./Students";
import StudentsPlusMinus from "./StudentsPlusMinus";
import StudentDetailV2 from "./StudentDetailV2";
import MyPlupusV2 from "./MyPlupusV2";
import StaffPluPuList from "./StaffList";
import StaffDetail from "./StaffDetail";

function PlupusRouter() {

    return (
        <Routes>
            <Route path="v2/students/" element={<Students/>}/>
            <Route path="v2/students/plus-minus" element={<StudentsPlusMinus/>}/>
            <Route path="v2/students/:studentId" element={<StudentDetailV2/>}/>
            <Route path="v2/my" element={<MyPlupusV2/>}/>
            <Route path="v2/staff" element={<StaffPluPuList/>}/>
            <Route path="v2/staff/:staffId" element={<StaffDetail/>}/>

        </Routes>
    )
}


export default function Plupus() {
    return <PlupusRouter/>
}
