import http from "./../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";


export async function getAbsencesReport(schoolYearId, startDate, endDate) {
    const resp = await http.get(`/student_logbook/reports/absences_by_student/${schoolYearId}/?start_date=${startDate}&end_date=${endDate}`)
    return resp.data
}

export async function getAbsencesReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/student_logbook/reports/absences_by_student/excel/${schoolYearId}/`)
    return resp.data
}

export async function getPenaltyEntryReport() {
    const resp = await http.get(`/student_logbook/reports/student_penalty_entries/`)
    return resp.data
}

export async function getPenaltyEntryReportExcel() {
    const resp = await httpServiceDownloads.get(`/student_logbook/reports/student_penalty_entries/excel/`)
    return resp.data
}

export async function getLogbookIpadReport() {
    const resp = await http.get(`/student_logbook/reports/ipad_violations/`)
    return resp.data
}
export async function getLogbookIpadReportExcel() {
    const resp = await httpServiceDownloads.get(`/student_logbook/reports/ipad_violations/excel/`)
    return resp.data
}


export async function getCourseMaterialReport(schoolYearId) {
    const resp = await http.get(`/school/reports/course_materials/${schoolYearId}/`)
    return resp.data
}

export async function getCourseMaterialReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/school/reports/course_materials/excel/${schoolYearId}/`)
    return resp.data
}

// grades
export async function getGradesEnteredReport(schoolYearId) {
    const resp = await http.get(`/grades/reports/grades_entered/${schoolYearId}/`)
    return resp.data
}

export async function getGradesEnteredReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/grades/reports/grades_entered/excel/${schoolYearId}/`)
    return resp.data
}


// class register
export async function getGivenHomeworkByTeacherReport(schoolYearId) {
    const resp = await http.get(`/class_register/reports/given_homework_by_teacher/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getGivenHomeworkByTeacherReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/given_homework_by_teacher/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}


export async function getCheckedHomeworkByStudentsReport(schoolYearId, dateFrom, dateTo) {
    const resp = await http.get(
        `/class_register/reports/checked_homework_by_student/?school_year_id=${schoolYearId}`
        + (dateFrom ? `&date_from=${dateFrom}` : '')
        + (dateTo ? `&date_to=${dateTo}` : ''))
    return resp.data
}

export async function getCheckedHomeworkByStudentsReportExcel(schoolYearId, dateFrom, dateTo) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/checked_homework_by_student/excel/?school_year_id=${schoolYearId}`
        + (dateFrom ? `&date_from=${dateFrom}` : '')
        + (dateTo ? `&date_to=${dateTo}` : ''))
    return resp.data
}

export async function getCheckedHomeworkByTeacherReport(schoolYearId, dateFrom, dateTo) {
    const resp = await http.get(
        `/class_register/reports/checked_homework_by_staff/?school_year_id=${schoolYearId}`
        + (dateFrom ? `&date_from=${dateFrom}` : '')
        + (dateTo ? `&date_to=${dateTo}` : ''))
    return resp.data
}

export async function getCheckedHomeworkByTeacherReportExcel(schoolYearId, dateFrom, dateTo) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/checked_homework_by_staff/excel/?school_year_id=${schoolYearId}`
        + (dateFrom ? `&date_from=${dateFrom}` : '')
        + (dateTo ? `&date_to=${dateTo}` : ''))
    return resp.data
}


export async function getMissingHomeworkOrMaterialsByStudentReport(schoolYearId, timePeriodId) {
    const resp = await http.get(`/class_register/reports/missing_homework_or_materials_students/?school_year_id=${schoolYearId}`
        + (timePeriodId ? `&time_period_id=${timePeriodId}` : '')
    )
    return resp.data
}

export async function getMissingHomeworkOrMaterialsByStudentReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/missing_homework_or_materials_students/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}



export async function getMissingHomeworkOrMaterialsByStudentAndTeacherReport(schoolYearId) {
    const resp = await http.get(`/class_register/reports/missing_homework_or_materials_students_teachers/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getMissingHomeworkOrMaterialsByStudentAndTeacherReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/missing_homework_or_materials_students_teachers/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getMissingHomeworkOrMaterialsByTeacherReport(schoolYearId) {
    const resp = await http.get(`/class_register/reports/missing_homework_or_materials_teachers/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getMissingHomeworkOrMaterialsByTeacherReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/class_register/reports/missing_homework_or_materials_teachers/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getStudentsWithTutoringRecommendationsReport(schoolYearId) {
    const resp = await http.get(`/assessments/reports/students_with_tutoring_recommendation/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getStudentsWithTutoringRecommendationsReportExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/assessments/reports/students_with_tutoring_recommendation/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getPluPuStudentsReport(timePeriodId) {
    if (!timePeriodId) {
        return []
    }
    const resp = await http.get(`/plupus/reports/students/?time_period_id=${timePeriodId}`)
    return resp.data
}

export async function getPluPuStudentsReportExcel(timePeriodId) {
    if (!timePeriodId) {
        return []
    }
    const resp = await httpServiceDownloads.get(`/plupus/reports/students/excel/?time_period_id=${timePeriodId}`)
    return resp.data
}
