import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getStudentPlusMinusListExcel, getStudentPlusMinusList} from "../../../api/plupus";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        sortingFn: "schoolClassSorting",
        filterFn: "containsString"
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },

    // Variable Pluspunkte
    {
        accessorKey: "plus_points_e1",
        id: "plus_points_e1",
        header: "+ E1",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.plus_points_e1 ? row.original.plus_points_e1 : 0}</>
        ),
    },
    {
        accessorKey: "plus_points_e2",
        id: "plus_points_e2",
        header: "+ E2",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.plus_points_e2 ? row.original.plus_points_e2 : 0}</>
        ),
    },
    {
        accessorKey: "plus_points_e3",
        id: "plus_points_e3",
        header: "+ E3",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.plus_points_e3 ? row.original.plus_points_e3 : 0}</>
        ),
    },
    {
        accessorKey: "plus_points_e4",
        id: "plus_points_e4",
        header: "+ E4",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.plus_points_e4 ? row.original.plus_points_e4 : 0}</>
        ),
    },

    // Variable Minuspunkte
    {
        accessorKey: "minus_points_e1",
        id: "minus_points_e1",
        header: "- E1",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.minus_points_e1 ? row.original.minus_points_e1 : 0}</>
        ),
    },
    {
        accessorKey: "minus_points_e2",
        id: "minus_points_e2",
        header: "- E2",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.minus_points_e2 ? row.original.minus_points_e2 : 0}</>
        ),
    },
    {
        accessorKey: "minus_points_e3",
        id: "minus_points_e3",
        header: "- E3",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.minus_points_e3 ? row.original.minus_points_e3 : 0}</>
        ),
    },
    {
        accessorKey: "minus_points_e4",
        id: "minus_points_e4",
        header: "- E4",
        enableColumnFilter: false,
        sortingFn: "basic",
        cell: ({row, value}) => (
            <>{row.original.minus_points_e4 ? row.original.minus_points_e4 : 0}</>
        ),
    }
];

export default function StudentsPlusMinus() {
    const {permissions} = useAuth();
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_v2", schoolYearId],
        () => getStudentPlusMinusList(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    );

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    function getRowLink(row) {
        return `/plupus/v2/students/${row.original.id}/`;
    }

    function getTrClassNames(row, index) {

        var current_rating = row.original.rating_e4
        if (row.original.status_e1 === 'CURRENT') {
            current_rating = row.original.rating_e1
        } else if (row.original.status_e2 === 'CURRENT') {
            current_rating = row.original.rating_e2
        } else if (row.original.status_e3 === 'CURRENT') {
            current_rating = row.original.rating_e3
        }

        return current_rating == "Grün" ? 'bg-green-50' : current_rating == "Orange" ? 'bg-orange-100' : 'bg-blue-50'
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Plupus Plus/Minus nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        <DownloadButton
                            apiFunction={() => getStudentPlusMinusListExcel(schoolYearId)}
                            fileName={`Plupus.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                                minSchoolYearId={39}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getTrClassNames={getTrClassNames}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
