import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getStudentList, getStudentListExcel} from "../../../api/plupus";
import {DownloadButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";

function getStatusClassName(status, index) {
    return status == "Grün" ? 'bg-green-50' : status == "Orange" ? 'bg-orange-100' : 'bg-blue-50'
}

const columns = [
    {
        accessorKey: "current_points_rank",
        id: "current_points_rank",
        header: "Rang",
        enableColumnFilter: false
    },
    {
        accessorKey: "forecast_points_rank",
        id: "forecast_points_rank",
        header: "Rang*",
        enableColumnFilter: false
    },
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        sortingFn: "schoolClassSorting",
        filterFn: "containsString"
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },

    {
        accessorKey: "points_e1",
        id: "points_e1",
        header: "E1",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e1}{row.original.status_e1 === 'FORECAST' ? ' *' : ''}</>
        ),
        statusClassNames: (row) => {return getStatusClassName(row.original.rating_e1)}
    },
    {
        accessorKey: "points_e2",
        id: "points_e2",
        header: "E2",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e2}{row.original.status_e2 === 'FORECAST' ? ' *' : ''}</>
        ),
        statusClassNames: (row) => {return getStatusClassName(row.original.rating_e2)}
    },
    {
        accessorKey: "points_e3",
        id: "points_e3",
        header: "E3",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e3}{row.original.status_e3 === 'FORECAST' ? ' *' : ''}</>
        ),
        statusClassNames: (row) => {return getStatusClassName(row.original.rating_e3)}
    },
    {
        accessorKey: "points_e4",
        id: "points_e4",
        header: "E4",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e4}{row.original.status_e4 === 'FORECAST' ? '*' : ''}</>
        ),
        statusClassNames: (row) => {return getStatusClassName(row.original.rating_e4)}
    }
];

export default function Students() {
    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_current_v2"],
        () => getStudentList()
    );

    function getRowLink(row) {
        return `/plupus/v2/students/${row.original.id}/`;
    }

    function getTrClassNames(row, index) {
        return row.original.rating_actual == "Grün" ? 'bg-green-50' : row.original.rating_actual == "Orange" ? 'bg-orange-100' : 'bg-blue-50'
    }

    const canViewPreview = checkPermission(permissions, "plupus.view_preview_grades")

    const previewColumn = {
        accessorKey: "points_forecast_with_grades",
        id: "points_forecast_with_grades",
        header: "mit Noten",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        statusClassNames: (row) => {return getStatusClassName(row.original.rating_forecast_with_grades)}
    }

    let previewPosition;
    let columnsWithPreview = [...columns]
    if (canViewPreview && data) {
        let first_row = data[0]
        if (first_row.f_ap === first_row.ap_id_1)  previewPosition = columns.findIndex(column => column.id === 'points_e1') + 1
        if (first_row.f_ap === first_row.ap_id_2)  previewPosition = columns.findIndex(column => column.id === 'points_e2') + 1
        if (first_row.f_ap === first_row.ap_id_3)  previewPosition = columns.findIndex(column => column.id === 'points_e3') + 1
        if (first_row.f_ap === first_row.ap_id_4)  previewPosition = columns.findIndex(column => column.id === 'points_e4') + 1

        if (previewPosition) columnsWithPreview.splice(previewPosition, 0, previewColumn)
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Aktuelle Plupus nach Schülern
                        </h3>
                        <p className="italic text-sm my-2"> * = Prognose</p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        <DownloadButton
                            apiFunction={() => getStudentListExcel()}
                            fileName={`Plupus.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columnsWithPreview}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getTrClassNames={getTrClassNames}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
