import Joi from "joi";
import { React, useEffect, useState } from "react";
import { Page } from "../../elements/Pages";

import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { addFileToResult, getMarkDefinition, resultApi } from "../../../api/grades";
import Form, { baseHandleChange, baseHandleDateChange } from "../../elements/forms/Form";
import { FormSectionContent } from "../../elements/forms/FormLayout";
import { DateInput, Input, SelectForStudent } from "../../elements/forms/Inputs";
import { useQueryClient } from '@tanstack/react-query';


export default function ResultCreateEdit() {

    const queryClient = useQueryClient()
    const {id, markDefinitionId} = useParams()

    const [markDefinition, setMarkDefinition] = useState()
    const [data, setData] = useState({
        id: null,
        mark_definition: {id: parseInt(markDefinitionId) || null},
        date: null,
        topic: false,
        result_lines: []
    })

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [resultValidationErrors, setResultValidationErrors] = useState([])

    const [errors, setErrors] = useState({})
    let navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [id, markDefinitionId])

    async function loadData() {

        let newData
        let markDefinition
        if (id) {
            newData = await resultApi('get', id)
            markDefinition = await getMarkDefinition(newData.mark_definition.id)
        } else if (markDefinitionId) {
            markDefinition = await getMarkDefinition(markDefinitionId)
            newData = {...data, mark_definition: markDefinition}
        }

        // add missing lines
        let result_lines = [...newData.result_lines]
        for (var i = 0; i < markDefinition.course.students.length; i++) {
            let student = markDefinition.course.students[i]

            if (!result_lines.find(l => l.student.id == student.id)) {
                const newLine = {
                    id: null,
                    student: student,
                    mark: null
                }
                result_lines.push(newLine)
            }
        }

        setMarkDefinition(markDefinition)
        setData({...newData, result_lines: result_lines})

    }

    const schema = {
        topic: Joi.string().required(),
        date: Joi.date().required()
    }

    async function onSave() {
        let newData
        try {
            if (!data.id) newData = await resultApi('post', null, data)
            else newData = await resultApi('patch', null, data)
            setData(newData)
            setResultValidationErrors([])

            // then adding the file
            if (isFilePicked) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                newData = await addFileToResult(formData, newData.id)
            }
            if (id) {
                queryClient.invalidateQueries({
                    queryKey: ["result_detail", id]
                })
            }
            toast.success("Ergebnis erfasst.")
            navigate(`/grades/results/${newData.id}`)
        } catch (e) {
            setResultValidationErrors(e.data)
            throw(e)
        }
    }

    async function onDelete() {
        await resultApi('delete', data.id)
        toast.success("Ergebnis gelöscht.")
        navigate(`/grades/courses/${data.mark_definition.course.id}`)
    }

    function handleChangeFile (event) {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    function handleResultLineChange(evt, studentId) {
        const newLines = data.result_lines.map(row => {
            if (row.student.id === studentId) {
                var value = evt.target.value
                return {...row, [evt.target.name]: value}
            }
            return row
        })
        setData({...data, result_lines: newLines})
    }

    if (!markDefinition) return <></>

    const title = (id || data.id) ? "Ergebnis bearbeiten" : "Neues Ergebnis eintragen"
    const successMessage = (id || data.id) ? "Ergebnis aktualisiert" : "Ergebnis eingetragen"

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto md:flex md:items-center md:justify-between lg:max-w-7xl">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            <h2 className="text-lg font-medium text-gray-900">{markDefinition.title} ({markDefinition.type})</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className=" max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="notes-title">

                        <Form id="createProjectParticipation" onSave={onSave}
                              onDelete={onDelete}
                              data={data} setData={setData}
                              className=""
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>

                            <FormSectionContent>

                                <DateInput
                                    className="col-span-3"
                                    path="date"
                                    label="Datum"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                />

                                <Input
                                    path="topic"
                                    type="text"
                                    label="Thema"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />


                                <div className="sm:col-span-6 text-sm">

                                    <h3 className="text-md font-medium mb-1">Anhang</h3>
                                    <input type="file" name="attachment" onChange={handleChangeFile}/> <br/>

                                    <div>
                                        {data.attachment && <p className="py-1">Bisherige Datei: {data.attachment}</p>}
                                    </div>
                                </div>

                                <div className="col-span-6 divide-y divide-gray-200">
                                    <div className="py-5 flex justify-between">
                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                            SchülerInnen
                                        </h2>
                                    </div>
                                    <div className="">

                                        {data.result_lines &&
                                            <div
                                                className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <tbody>
                                                    {data.result_lines.map((l, index) => (
                                                        <tr key={index}
                                                            className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                        >
                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                {l.student.full_name}
                                                            </td>

                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                <SelectForStudent
                                                                    path="mark"
                                                                    label="Note"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleResultLineChange}
                                                                    // errors={projectStudentTimeErrors}
                                                                    options={markDefinition.result_marks}
                                                                    labelAttr="display"
                                                                    valueAttr="id"
                                                                    valueOnly={false}
                                                                    isClearable={true}
                                                                    data={data.result_lines}
                                                                    studentId={l.student.id}
                                                                />
                                                            </td>

                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            </div>}

                                    </div>

                                </div>

                                {resultValidationErrors && <div className="px-2 bg-red-50 text-red-700 sm:col-span-6">
                                    <ul role="list" className="space-y-1">
                                        {resultValidationErrors.map((error, index) => (
                                            <li key={index} className="flex py-2">{error}</li>
                                        ))}
                                    </ul>

                                </div>}

                            </FormSectionContent>

                        </Form>

                        <Link to={`/grades/courses/${markDefinition.course.id}`}
                              className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                            Liste</Link>
                    </section>

                </div>

                <section aria-labelledby="timeline-title"
                         className="lg:col-start-3 lg:col-span-1 mt-6"
                >
                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">
                    </section>

                    <section aria-labelledby="applicant-information-title" className="bg-gray-100 mt-8">
                    </section>
                </section>
            </div>


        </Page>
    )
}
