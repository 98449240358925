import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {CalendarButton, EditButton, PrimaryButton} from "../../elements/Buttons";
import {extraExamApi, extraExamLineApi, getExtraExamPDF} from "../../../api/school";
import checkPermission from "../../../utils/permissions";

const columns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row.student,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: 'extra_exam_id',
        id: 'pdf',
        disableRowLink: true,
        cell: (cell) => <TableDownloadLink
            label="PDF"
            fileName={`Nachholklausur.pdf`}
            apiFunction={() => getExtraExamPDF(cell.getValue())}/>,
        header: 'PDF',
        enableColumnFilter: false
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
                <EditButton
                    iconOnly={true}
                    link={`/class-register/extra-exams/${row.original.extra_exam_id}/edit`}/>
            </>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true,
        headerClassNames: "narrow-filter"
    }
];

export default function ExtraExamList() {

    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["extra_exam_lines"],
        () => extraExamLineApi('get'),
        {refetchInterval: 1000 * 60 * 60 }
    )

    function getRowLink(row) {
        return `/class-register/extra-exams/${row.original.extra_exam_id}/`;
    }

    const canAddExam = checkPermission(permissions, "school.add_extraexam")

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Nachholklausuren
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <CalendarButton link={"/class-register/exams/calendar"} label="Klausurenkalender"/>

                        {canAddExam &&
                            <PrimaryButton link={"/class-register/extra-exams/new"} label="Nachholklausur eintragen"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
