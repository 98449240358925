import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";


// V2
export async function getStudentList() {
    const resp = await http.get(`/plupus/v2/students/`)
    return resp.data
}

export async function getStudentListExcel() {
    const resp = await httpServiceDownloads.get(`/plupus/v2/students/excel/`)
    return resp.data
}


export async function getStudentPlusMinusList(schoolYearId) {
    const resp = await http.get(`/plupus/v2/students_plus_minus/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getStudentPlusMinusListExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/plupus/v2/students_plus_minus/excel/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
    )
    return resp.data
}

export async function getStudentDetailV2(studentId) {
    const resp = await http.get(`/plupus/v2/students/${studentId}/`)
    return resp.data
}

export async function getStatusSummaryData() {
    const resp = await http.get(`/plupus/v2/students/summary/`)
    return resp.data
}


export async function getPlupuStaffList(schoolYearId) {
    const resp = await http.get(`/plupus/v2/staff/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getPlupuStaffListExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/plupus/v2/staff/excel/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
    )
    return resp.data
}

export async function getStaffDetail(staffId) {
    const resp = await http.get(`/plupus/v2/staff/${staffId}/`)
    return resp.data
}

export async function getEffectiveTimePeriod() {
    const resp = await http.get(`/plupus/v2/effective_epoch/`)
    return resp.data
}