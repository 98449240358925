import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";


export async function getClassRegisterEntries(schoolYearId, page, search) {
    const resp = await http.get(`/class_register_entries/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
        // + (courseId ? `&course_id=${courseId}` : ''))
        + (page > 1 ? `&page=${page}` : '')
        + (search ? `&search=${search}` : '')
    )
    return resp.data
}

export async function getClassRegisterEntriesForCourse(courseId, page, search) {
    const resp = await http.get(`/class_register_entries/?course_id=${courseId}`
        + (page > 1 ? `&page=${page}` : '')
        + (search ? `&search=${search}` : '')
    )
    return resp.data
}

export async function classRegisterEntryApi(method, id, data) {
    return genericApi('/class_register_entries/', method, id, data)
}

export async function getHomeworkDataForCourse(courseId, previousOrCurrent) {
    const resp = await http.get(`/class_register/course_homework/${courseId}/?previous_or_current=${previousOrCurrent}`)
    return resp.data
}

export async function getHomeworkDataForStudent(studentId, previousOrCurrent, date) {
    const resp = await http.get(`/class_register/student_homework/${studentId}/?previous_or_current=${previousOrCurrent}`
    + (date ? `&date=${date}` : '')
    )
    return resp.data
}

export async function getClassRegisterDataForStudent(studentId, previousOrCurrent) {
    const resp = await http.get(`/class_register/student_class_register/${studentId}/?previous_or_current=${previousOrCurrent}`)
    return resp.data
}

export async function markHomeworkDone(homeworkStudentId) {
    const resp = await http.post(`/class_register/mark_homework_done/${homeworkStudentId}/`)
    return resp.data
}

export async function checkHomework(homeworkStudentId) {
    const resp = await http.post(`/class_register/check_homework/${homeworkStudentId}/`)
    return resp.data
}

export async function homeworkApi(method, id, data) {
    return genericApi('/homework/', method, id, data)
}

export async function deleteHomeworkMaterial(id) {
    const resp = await http.delete(`/homework_materials/${id}/`)
    return resp.data
}

export async function addFileToHomeworkMaterial(formData, id) {
    const resp = await http.post(`/class_register/add_file_to_homework_material/${id}/`, formData)
    return resp.data
}

export async function addFileToHomework(formData, id) {
    const resp = await http.post(`/homework/${id}/add_file/`, formData)
    return resp.data
}

export async function getExperiments() {
    const resp = await http.get(`/experiments/`)
    return resp.data
}

export async function getAbsenceNoticesAndHomeworkForCourse(courseId, date) {
    const resp = await http.get(`/class_register/absence_notices_and_homework/${courseId}/?date=${date}`)
    return resp.data
}

export async function startClassRegisterEntryExport() {
    const resp = await http.get(`/class_register_entries/start_export/`);
    return resp.data;
}

export async function getClassRegisterExcelForCourse(courseId) {
    const resp = await httpServiceDownloads.get(`/class_register/entries_for_course_excel/${courseId}`)
    return resp.data
}

export async function getStatistcs() {
    const resp = await http.get(`/class_register/statistics/`);
    return resp.data;
}

export async function startClassRegisterReminder(courseId) {
    const resp = await http.get(`/class_register/send_reminder/${courseId}`);
    return resp.data;
}