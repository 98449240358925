import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getExamsByMonth, getSchoolClasses, getStudentInfoAll} from "../api/school";
import {getWeekNumber} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import {getCourseList} from "../api/courses";

const ExamContext = React.createContext();
const today = new Date();

export function useExams() {
    return useContext(ExamContext);
}

export function ExamProvider({children}) {
    const [view, setView] = useState("month");
    const [selectedDate, setSelectedDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate(),
    });
    const [selectedDateWeek, setSelectedDateWeek] = useState(
        getWeekNumber(today)
    );
    const [filter, setFilter] = useState({
        courseId: null,
        schoolClassIds: [],
        studentIds: []
    });
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventDetailOpen, setEventDetailOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loadingMonth = false

    const [fetchedEventsMonth, setFetchedEventsMonth] = useState([])

    const {data: courses} = useQuery(["course_list"], getCourseList, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: schoolClasses} = useQuery(["school_classes"], getSchoolClasses, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: students} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24});

    const openEvent = (event) => {
        if (!event) return;
        const date = event.event_type === 'exam' ? new Date(event.start) : new Date(event.date);
        const eventDate = {
            year: date.getFullYear(),
            month: date.getMonth()+1,
            date: date.getDate(),
        }
        setSelectedEvent(event);
        setEventDetailOpen(true);
        const link = event.event_type === 'exam'
            ? `/class-register/exams/calendar?exam_id=${event.id}&year=${eventDate.year}&month=${eventDate.month}`
            : `/class-register/exams/calendar?extra_exam_id=${event.id}&year=${eventDate.year}&month=${eventDate.month}`
        navigate(link)
    };

    useEffect(() => {
        const weekNumber = getWeekNumber(
            new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
        );
        setSelectedDateWeek(weekNumber);
    }, [selectedDate]);

    useEffect(() => {
        if (!eventDetailOpen && loaded) navigate("/class-register/exams/calendar");
        else setLoaded(true);
    }, [eventDetailOpen]);

    useEffect(() => {
        loadData()
    }, [filter, selectedDate]);

    async function loadData() {
        const newEvents = await getExamsByMonth(selectedDate.year, selectedDate.month, filter.courseId, filter.schoolClassIds, filter.studentIds)
        setFetchedEventsMonth(newEvents)
    }

    const value = {
        selectedDate,
        setSelectedDate,
        fetchedEventsMonth,
        courses,
        schoolClasses,
        students,
        view,
        setView,
        selectedEvent,
        openEvent,
        eventDetailOpen,
        setEventDetailOpen,
        filter,
        setFilter,
        loadingWeek: false,
    };

    return (
        <ExamContext.Provider value={value}>
            {!loadingMonth && children}
        </ExamContext.Provider>
    );
}
