import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {
    getLogbookIpadReport,
    getLogbookIpadReportExcel,
    getPluPuStudentsReport,
    getPluPuStudentsReportExcel
} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";
import {getTimePeriods} from "../../../api/calendar";
import {Select} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";
import {getCourseTopics} from "../../../api/courses";
import {getEffectiveTimePeriod} from "../../../api/plupus";

function getStatusClassName(status, index) {
    return status == "Grün" ? 'bg-green-50' : status == "Orange" ? 'bg-orange-100' : 'bg-blue-50'
}

var columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student_no_image",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "points",
        id: "points",
        columnType: "number",
        sortingFn: "basic",
        header: "Punkte",
        enableColumnFilter: false
    },
    {
        accessorKey: "rating",
        id: "rating",
        header: "Status",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        statusClassNames: (row) => {return getStatusClassName(row.original.rating)}
    },
    {
        accessorKey: "school",
        id: "school",
        columnType: "number",
        sortingFn: "basic",
        header: "Schule",
        enableColumnFilter: false,
    },,
    {
        accessorKey: "boarding_school",
        id: "boarding_school",
        columnType: "number",
        sortingFn: "basic",
        header: "Internat",
        enableColumnFilter: false,
    },,
    {
        accessorKey: "studierzeit",
        id: "studierzeit",
        columnType: "number",
        sortingFn: "basic",
        header: "StuZi",
        enableColumnFilter: false,
    },,
    {
        accessorKey: "gpa_imp",
        id: "gpa_imp",
        sortingFn: "basic",
        columnType: "number",
        header: "Verbesserung",
        enableColumnFilter: false,
    },
    {
        accessorKey: "leadership_roles",
        id: "leadership_roles",
        columnType: "number",
        sortingFn: "basic",
        header: "Ämter",
        enableColumnFilter: false,
    },
    {
        accessorKey: "non_smoker",
        id: "non_smoker",
        columnType: "number",
        sortingFn: "basic",
        header: "Nichtraucher",
        enableColumnFilter: false,
    },
    {
        accessorKey: "plus_points",
        id: "plus_points",
        columnType: "number",
        sortingFn: "basic",
        header: "Plus",
        enableColumnFilter: false,
    },
    {
        accessorKey: "minus_points",
        id: "minus_points",
        columnType: "number",
        sortingFn: "basic",
        header: "Minus",
        enableColumnFilter: false,
    }
]


export default function PlupuStudentsReports() {

    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        time_period: null
    })

    const {data: timePeriods} = useQuery(['time_periods'], getTimePeriods, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: effectiveTimePeriod} = useQuery(['effective_epoch'], getEffectiveTimePeriod, {refetchInterval: 1000 * 60 * 60 * 24})

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupu_students_report", formData.time_period?.id],
        () => getPluPuStudentsReport(formData.time_period?.id),
        {
            refetchInterval: 1000 * 60 * 60,
            enabled: !!formData.time_period?.id
        }
    )

    useEffect(() => {
        if (effectiveTimePeriod) {
            setFormData({time_period: effectiveTimePeriod})
        }
    }, [effectiveTimePeriod]);

    function getRowLink(row) {
        return `/plupus/v2/students/${row.original.id}`;
    }

    function handleChange(evt) {
        baseHandleChange(evt, formData, setFormData, errors, setErrors, {})
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            PluPus nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getPluPuStudentsReportExcel(formData.time_period?.id)}
                            fileName="PlupuReport.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block max-w-2xl py-2 align-middle md:px-6 lg:px-8">
                            <Select
                                path="time_period"
                                label="Epoche"
                                className="sm:col-span-6"
                                options={timePeriods}
                                valueAttr="id"
                                labelAttr="label"
                                onChange={handleChange}
                                data={formData}
                                isClearable={false}
                                valueOnly={false}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
