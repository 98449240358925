import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ExamProvider} from "../../../contexts/ExamsContext";
import ExamCalendar from "./ExamCalendar";
import ExamCreateEdit from "./ExamCreateEdit";
import ExamWarnings from "./ExamWarnings";
import ExamList from "./ExamList";
import ExtraExamList from "./ExtraExamList";
import ExtraExamDetailPage from "./ExtraExamDetail";
import ExtraExamCreateEdit from "./ExtraExamCreateEdit";
import ClassRegisterEntries from "./ClassRegisterEntries";
import ClassRegisterEntryDetail from "./ClassRegisterEntryDetail";
import CourseDetail from "./CourseDetail";
import CourseHomework from "./CourseHomework";
import StudentDetail from "./StudentDetail";
import MyHomework from "./MyHomework";
import HomeworkDetail from "./HomeworkDetail";
import HomeworkCreateEdit from "./HomeworkCreateEdit";
import ClassRegisterEntryCreateEdit from "./ClassRegisterEntryCreateEdit";
import Statistics from "./Statistics";
import {ExamDetailPage} from "./ExamDetail";


function ClassRegisterRouter() {

    return (
        <Routes>

            {/* Exams */}
            <Route
                path="/exams/"
                element={<Navigate to="/class-register/exams/calendar" replace={true}/>}
            />
            <Route
                path="/exams/calendar"
                element={<ExamProvider><ExamCalendar/></ExamProvider>}
            />

            <Route path="/exams/new" element={<ExamCreateEdit/>}/>
            <Route path="/exams/new/:courseId" element={<ExamCreateEdit/>}/>
            <Route path="/exams/:examId" element={<ExamDetailPage/>}/>
            <Route path="/exams/:id/edit" element={<ExamCreateEdit/>}/>

            <Route path="/exams/warnings" element={<ExamWarnings/>}/>
            <Route path="/exams/list" element={<ExamList/>}/>

            <Route path="/extra-exams" element={<ExtraExamList/>}/>
            <Route path="/extra-exams/new" element={<ExtraExamCreateEdit/>}/>
            <Route path="/extra-exams/:id/edit" element={<ExtraExamCreateEdit/>}/>
            <Route path="/extra-exams/:id" element={<ExtraExamDetailPage/>}/>

            <Route path="/entries" element={<ClassRegisterEntries/>}/>
            {/*<Route path="/entries/new" element={<ClassRegisterEntryCreateEdit />} />*/}
            <Route path="/entries/new/:courseId" element={<ClassRegisterEntryCreateEdit/>}/>
            <Route path="/entries/:id/edit" element={<ClassRegisterEntryCreateEdit/>}/>
            <Route path="/entries/:id" element={<ClassRegisterEntryDetail/>}/>

            <Route path="/courses/:courseId" element={<CourseDetail/>}/>
            <Route path="/courses/:courseId/homework" element={<CourseHomework/>}/>

            <Route path="/students/:studentId" element={<StudentDetail/>}/>
            <Route path="/my" element={<MyHomework/>}/>

            <Route path="/homework/:id" element={<HomeworkDetail/>}/>
            <Route path="/homework/new/:courseId" element={<HomeworkCreateEdit/>}/>
            <Route path="/homework/:id/edit" element={<HomeworkCreateEdit/>}/>

            <Route path="/statistics" element={<Statistics/>}/>

        </Routes>
    )
}


export default function ClassRegister() {
    return <ClassRegisterRouter/>
}
